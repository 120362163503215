import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Permission } from '../models/enums/permissions.enum';
import { PermissionService } from '../services/permission.service';

export const permissionGuard: CanActivateChildFn = (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): boolean | UrlTree => {
  const permissionService = inject(PermissionService);
  const router = inject(Router);

  const permissionMap: { [key: string]: Permission } = {
    accounts: Permission.LICENSE_READ,
    admins: Permission.ADMINS_READ,
    article: Permission.CATEGORIES_READ,
    categories: Permission.CATEGORIES_READ,
    faq: Permission.FAQ_READ,
    licenses: Permission.LICENSE_READ,
    partners: Permission.PARTNER_SERVICE_READ,
    parts: Permission.PARTS_READ,
    roles: Permission.ROLES_READ,
    subscriptions: Permission.SUBSCRIPTIONS_READ,
    users: Permission.USERS_READ,
  };

  if (state.url.includes('dashboard')) {
    return true;
  }

  for (const [key, permission] of Object.entries(permissionMap)) {
    if (state.url.includes(key) && permissionService.checkPermissions([permission])) {
      return true;
    }
  }

  return router.createUrlTree(['/']);
};
